import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ProfileService from '@/services/user';
// components
import Btn from '@/components/Button/Btn.vue';
import ProjectCard from '@/views/Projects/Components/ProjectCard/ProjectCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SearchInput from '@/components/FormInputs/SearchInput/SearchInput.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
let Projects = class Projects extends Vue {
    constructor() {
        super(...arguments);
        this.filteredTopic = null;
        this.searchValue = '';
        this.searchError = [];
        this.loading = true;
        this.paginationLoaded = false;
        this.showFilters = true;
        this.onlyFavourites = false;
        this.coverImageDesc = "YOU can make a difference! Hand-picked projects of real benefit to Africa's people, ecosystems & wildlife.";
        this.searchTimeout = null;
    }
    async onTopicFilterChange(newVal) {
        this.loading = true;
        // Set the filter ID and reset page
        await this.$store.dispatch('projects/setFilter', newVal);
        if (this.$route.query.label !== newVal) {
            await this.$store.dispatch('projects/setPage', 1);
        }
        else {
            await this.$store.dispatch('projects/setPage', this.$route.query.page);
        }
        // Call the page load
        await this.$store.dispatch('projects/getProjects');
        this.loading = false;
    }
    async searchUpdated(newVal) {
        // We do this timeout cllear timeout dance
        // so that the search is only triggered 300ms after the user has stopped typing.
        // We trigger loading immediately though to provide feedback
        clearTimeout(this.searchTimeout);
        this.loading = true;
        // Set the search term & rest page
        await this.$store.dispatch('projects/setSearch', newVal);
        if (this.$route.query.search !== newVal) {
            await this.$store.dispatch('projects/setPage', 1);
        }
        else {
            await this.$store.dispatch('projects/setPage', this.$route.query.page);
        }
        let self = this;
        this.searchTimeout = setTimeout(async function () {
            // Call the page load
            await self.$store.dispatch('projects/getProjects');
            self.loading = false;
        }, 500);
    }
    created() {
        if (history.state.search && history.state.label && history.state.page) {
            if (this.$route.fullPath === `/projects?search=${history.state.search}&label=${history.state.label}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/projects?search=${history.state.search}&label=${history.state.label}&page=${history.state.page}` });
            }
        }
        else if (history.state.search && history.state.page) {
            if (this.$route.fullPath === `/projects?search=${history.state.search}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/projects?search=${history.state.search}&page=${history.state.page}` });
            }
        }
        else if (history.state.label && history.state.page) {
            if (this.$route.fullPath === `/projects?label=${history.state.label}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/projects?label=${history.state.label}&page=${history.state.page}` });
            }
        }
    }
    async mounted() {
        await this.resetProjectValues();
        if (this.$route.query.search) {
            await this.$store.dispatch('projects/setSearch', this.$route.query.search);
            this.searchValue = this.$store.getters['projects/getSearchTerm'];
        }
        if (this.$route.query.page) {
            await this.$store.dispatch('projects/setPage', this.$route.query.page);
        }
        if (this.$route.query.label) {
            await this.$store.dispatch('projects/setFilter', this.$route.query.label);
            this.filteredTopic = this.$store.getters['projects/getFilter'];
        }
        await this.$store.commit('projects/SET_ONLY_FAVOURITES', false);
        // Call the page load
        await this.$store.dispatch('projects/getProjects');
        this.paginationLoaded = true;
        this.loading = false;
        await this.$store.dispatch('label/getAllLabels');
        if (this.$store.getters['auth/loggedIn']) {
            await ProfileService.updateLastActivity("projects");
        }
    }
    get sortedLabels() {
        let sortedLabels = this.labels;
        sortedLabels.sort((a, b) => {
            let lowerCaseA = a.name.toLowerCase();
            let lowerCaseB = b.name.toLowerCase();
            if (lowerCaseA < lowerCaseB) {
                return -1;
            }
            if (lowerCaseA > lowerCaseB) {
                return 1;
            }
            return 0;
        });
        return sortedLabels;
    }
    async beforeDestroy() {
        await this.resetProjectValues();
    }
    async filterFavourites() {
        if (!this.$store.getters['auth/loggedIn']) {
            this.$store.dispatch('notification/showErrorModalGuest', "Please register or login to use this feature.");
            return;
        }
        this.onlyFavourites = !this.onlyFavourites;
        await this.$store.dispatch('projects/setOnlyFavourites', this.onlyFavourites);
        await this.$store.dispatch('projects/getProjects');
    }
    async toggleFilterSection() {
        this.showFilters = !this.showFilters;
        if (!this.showFilters) {
            await this.$store.dispatch('projects/setFilter', '');
            await this.$store.dispatch('projects/setOnlyFavourites', false);
            await this.$store.dispatch('projects/setPage', 1);
            await this.$store.dispatch('projects/getProjects');
            this.searchValue = '';
            this.filteredTopic = null;
            this.onlyFavourites = false;
        }
    }
    async resetProjectValues() {
        await this.$store.dispatch('projects/setSearch', '');
        await this.$store.dispatch('projects/setFilter', '');
        await this.$store.dispatch('projects/setPage', 1);
    }
    async paginate(page) {
        await this.$store.dispatch('projects/setPage', page);
        var query = {};
        query['page'] = page.toString();
        if (this.searchValue != '') {
            query['search'] = this.searchValue;
        }
        if (this.filteredTopic != null) {
            query['label'] = this.filteredTopic;
        }
        this.$router.push({
            name: 'projects',
            query: query,
        });
    }
};
__decorate([
    Getter('getProjects', { namespace: 'projects' })
], Projects.prototype, "projects", void 0);
__decorate([
    Getter('pagination', { namespace: 'pagination' })
], Projects.prototype, "pagination", void 0);
__decorate([
    Getter('getLabels', { namespace: 'label' })
], Projects.prototype, "labels", void 0);
__decorate([
    Watch('filteredTopic')
], Projects.prototype, "onTopicFilterChange", null);
__decorate([
    Watch('searchValue')
], Projects.prototype, "searchUpdated", null);
Projects = __decorate([
    Component({
        components: {
            Btn,
            ProjectCard,
            TextInput,
            SearchInput,
            SelectInput,
            CoverImage,
            Pagination,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | projects',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic projects',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/projects` }],
            };
        },
    })
], Projects);
export default Projects;
