import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
//components
import Card from '@/components/Card/Card.vue';
import LabelList from '@/components/Label/LabelList/LabelList.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let ProjectCard = class ProjectCard extends Vue {
    mounted() { }
    async click() {
        await this.$store.dispatch('projects/setActiveProject', this.project);
        this.$router.push({ path: `/project/${this.project.slug}` });
    }
    handleClickEvent(e) {
        this.$emit("topic-changed", e);
    }
    getCoverImage() {
        if (this.project.hero_image && this.project.hero_image.public_url) {
            return this.project.hero_image.public_url;
        }
        else if (this.project.images.length > 0) {
            return this.project.images[0].public_url;
        }
        else {
            return null;
        }
    }
    getCoverImageCopyright() {
        if (this.project.hero_image && this.project.hero_image.public_url && this.project.hero_image_copyright) {
            return this.project.hero_image_copyright;
        }
        else {
            return '';
        }
    }
    async pushHistoryState(url) {
        await history.pushState({ search: this.$store.state.projects.searchTerm, page: this.$store.state.projects.page }, 'Projects', this.$route.fullPath);
        this.$router.push({ path: url });
    }
};
__decorate([
    Prop()
], ProjectCard.prototype, "project", void 0);
ProjectCard = __decorate([
    Component({
        components: { Card, LabelList, MarkdownFormatter },
    })
], ProjectCard);
export default ProjectCard;
