import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
//components
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import Btn from '@/components/Button/Btn.vue';
import SubscriptionService from '@/services/subscriptions';
let ProjectDonateForm = class ProjectDonateForm extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.loadingPlans = true;
        this.loadingPaymentMethods = true;
        this.loadingSetupIntent = true;
        this.addNewMethod = false;
        this.updating = false;
        // subscribed: boolean = false
        this.stripe_key = process.env.VUE_APP_STRIPE_KEY;
        this.stripe = undefined;
        this.card = undefined;
        this.payment_methods = [];
        this.car_holder_name = '';
        this.setup_intent = [];
        this.payment_method = null;
        this.payment_method_options = [];
        this.quantity = 1;
        this.form = {
            project_id: this.project.id,
            quantity: 1,
            reason: '',
        };
        this.errors = {
            quantity: [],
            project_id: [],
            reason: [],
        };
    }
    mounted() {
        this.car_holder_name = this.$store.getters['user/getProfile'].name;
        /* @ts-ignore */
        this.stripe = Stripe(this.stripe_key);
        this.card = this.stripe.elements().create('card');
        this.card.mount(this.$refs.card);
        this.getPaymentMethods();
    }
    hidePanel() {
        this.$emit('close');
    }
    async getPaymentMethods() {
        try {
            let response = await SubscriptionService.getPaymentMethods();
            this.payment_methods = response.data.payment_methods;
            if (this.payment_methods.length == 0) {
                await this.createSetupIntent();
                this.addNewMethod = true;
            }
            else {
                this.payment_method_options = this.payment_methods?.map((method) => {
                    return {
                        name: `${method.card.brand.toUpperCase()} ending ${method.card.last4}`,
                        value: method.id,
                        selected: false,
                    };
                });
                this.payment_method = this.payment_method_options[0].value;
            }
            this.loadingPaymentMethods = false;
        }
        catch (e) {
            await this.$store.dispatch('notification/showErrorModal', e.response.data.message);
            this.errors = e;
            this.loadingPaymentMethods = false;
        }
    }
    async createSetupIntent() {
        try {
            let response = await SubscriptionService.createSetupIntent();
            this.setup_intent = response.data.setup_intent;
            this.loadingSetupIntent = false;
        }
        catch (e) {
            await this.$store.dispatch('notification/showErrorModal', e.response.data.message);
            this.errors = e;
            this.loadingSetupIntent = false;
        }
    }
    async createPaymentMethod() {
        try {
            await this.$store.dispatch('notification/hideNotification');
            this.updating = true;
            const { paymentMethod, error } = await this.stripe.createPaymentMethod('card', this.card, {
                billing_details: {
                    name: this.car_holder_name,
                },
            });
            if (error) {
                // Display "error.message" to the user...
                await this.$store.dispatch('notification/showErrorModal', error.message);
                this.updating = false;
            }
            else {
                // The card has been verified successfully...
                this.payment_method = paymentMethod.id;
                await this.makeDonation();
            }
        }
        catch (e) {
            this.updating = false;
            await this.$store.dispatch('notification/showErrorModal', 'Error');
            this.errors = e;
        }
    }
    async makeDonation() {
        try {
            this.updating = true;
            // Send through to the API the amount and card for processing
            let response = await SubscriptionService.attemptSingleDonation({
                payment_method: this.payment_method,
                donation_amount: Math.floor(this.form.quantity),
                project: this.project.id,
                reason: this.form.reason,
            });
            await this.$store.dispatch('notification/showSuccessModal', response.message);
            this.updating = false;
        }
        catch (e) {
            this.updating = false;
            await this.$store.dispatch('notification/showErrorModal', e.response.data.message);
            this.errors = e;
        }
    }
};
__decorate([
    Prop()
], ProjectDonateForm.prototype, "project", void 0);
ProjectDonateForm = __decorate([
    Component({
        components: { TextInput, SelectInput, Btn },
    })
], ProjectDonateForm);
export default ProjectDonateForm;
