import Api from './api';
export default {
    // GET
    getPlans() {
        return Api()
            .get(`subscriptions/stripe/plans`)
            .then((response) => response.data);
    },
    getProjectPlans() {
        return Api()
            .get(`subscriptions/stripe/project-plans`)
            .then((response) => response.data);
    },
    getSubscriptions() {
        return Api()
            .get(`subscriptions/stripe/subscriptions`)
            .then((response) => response.data);
    },
    getPaymentMethods() {
        return Api()
            .get(`subscriptions/stripe/payment-methods`)
            .then((response) => response.data);
    },
    createSetupIntent() {
        return Api()
            .post(`subscriptions/stripe/create-setup-intent`)
            .then((response) => response.data);
    },
    updatePaymentMethod() {
        return Api()
            .post(`subscriptions/stripe/update-payment-method`)
            .then((response) => response.data);
    },
    createSubscription(params) {
        return Api()
            .post(`subscriptions/stripe/create-subscription`, params)
            .then((response) => response.data);
    },
    addPlan(params) {
        return Api()
            .post(`subscriptions/stripe/add-plan`, params)
            .then((response) => response.data);
    },
    removePlan(params) {
        return Api()
            .post(`subscriptions/stripe/remove-plan`, params)
            .then((response) => response.data);
    },
    cancelSubscription() {
        return Api()
            .post(`subscriptions/stripe/cancel-subscription`, {})
            .then((response) => response.data);
    },
    resumeSubscription() {
        return Api()
            .post(`subscriptions/stripe/resume-subscription`, {})
            .then((response) => response.data);
    },
    attemptSingleDonation(data) {
        return Api()
            .post(`subscriptions/stripe/attempt-single-donation`, data)
            .then((response) => response.data);
    },
};
