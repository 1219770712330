import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
//components
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import Btn from '@/components/Button/Btn.vue';
//service
import ProjectService from '@/services/projects';
import SubscriptionService from '@/services/subscriptions';
import { loadStripe } from '@stripe/stripe-js';
let ProjectSubscriptionForm = class ProjectSubscriptionForm extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.loadingPlans = true;
        this.loadingPaymentMethods = true;
        this.loadingSetupIntent = true;
        this.addNewMethod = false;
        this.updating = false;
        this.subscribed = false;
        this.stripe_key = process.env.VUE_APP_STRIPE_KEY;
        this.stripe = undefined;
        this.card = undefined;
        this.payment_methods = [];
        this.car_holder_name = '';
        this.setup_intent = [];
        this.product_id = process.env.VUE_APP_STRIPE_PRODUCT_ID;
        this.price_id = process.env.VUE_APP_STRIPE_PRICE_ID;
        this.payment_method = null;
        this.payment_method_options = [];
        this.has_subscription = false;
        this.project_subscription = null;
        this.quantity = 1;
        this.form = {
            project_id: this.project.id,
            quantity: 1,
        };
        this.errors = {
            quantity: [],
            project_id: [],
        };
    }
    async mounted() {
        this.checkSubscription();
        this.car_holder_name = this.$store.getters['user/getProfile'].name;
        /* @ts-ignore */
        this.stripe = await loadStripe(this.stripe_key);
        this.card = this.stripe.elements().create('card');
        this.card.mount(this.$refs.card);
        this.getPaymentMethods();
    }
    hidePanel() {
        this.$emit('close');
    }
    checkSubscription() {
        const profile = this.$store.getters['user/getProfile'];
        let hasSubscription = false;
        profile.project_subscriptions?.forEach((project_subscription) => {
            if (project_subscription.project_id == this.project.id) {
                hasSubscription = true;
                this.project_subscription = project_subscription;
            }
        });
        this.has_subscription = hasSubscription;
        this.loading = false;
    }
    async getPaymentMethods() {
        try {
            let response = await SubscriptionService.getPaymentMethods();
            this.payment_methods = response.data.payment_methods;
            if (this.payment_methods.length == 0) {
                this.createSetupIntent();
                this.addNewMethod = true;
            }
            else {
                this.payment_method_options = this.payment_methods?.map((method) => {
                    return {
                        name: `${method.card.brand.toUpperCase()} ending ${method.card.last4}`,
                        value: method.id,
                        selected: false,
                    };
                });
                this.payment_method = this.payment_method_options[0].value;
            }
            this.loadingPaymentMethods = false;
        }
        catch (e) {
            this.$store.dispatch('notification/showErrorModal', e.response.data.message);
            this.errors = e;
            this.loadingPaymentMethods = false;
        }
    }
    async createSetupIntent() {
        try {
            let response = await SubscriptionService.createSetupIntent();
            this.setup_intent = response.data.setup_intent;
            this.loadingSetupIntent = false;
        }
        catch (e) {
            this.$store.dispatch('notification/showErrorModal', e.response.data.message);
            this.errors = e;
            this.loadingSetupIntent = false;
        }
    }
    async createPaymentMethod() {
        try {
            this.$store.dispatch('notification/hideNotification');
            this.updating = true;
            const { setupIntent, error } = await this.stripe.confirmCardSetup(this.setup_intent.val.client_secret, {
                payment_method: {
                    card: this.card,
                    billing_details: {
                        name: this.car_holder_name,
                    },
                },
            });
            if (error) {
                // Display "error.message" to the user...
                this.$store.dispatch('notification/showErrorModal', error.message);
                this.updating = false;
            }
            else {
                // The card has been verified successfully...
                this.payment_method = setupIntent.payment_method;
                this.createSubscription();
            }
        }
        catch (e) {
            this.updating = false;
            this.$store.dispatch('notification/showErrorModal', 'Error');
            this.errors = e;
        }
    }
    async createSubscription() {
        try {
            this.updating = true;
            let subscription = await SubscriptionService.createSubscription({
                type: 'project',
                product_id: this.product_id,
                price_id: this.price_id,
                payment_method: this.payment_method,
                trial_days: 0,
                quantity: this.form.quantity,
                metadata: {
                    model_type: 'App\\Models\\Project',
                    model_id: this.project.id,
                },
            });
            this.subscribed = true;
            this.updating = false;
            await this.$store.dispatch('notification/showSuccessModal', 'Thanks! We have added this project to your monthly subscription.');
            await this.$store.dispatch('user/getUserProfile');
            this.checkSubscription();
        }
        catch (e) {
            this.updating = false;
            this.$store.dispatch('notification/showErrorModal', e.response.data.message);
            this.errors = e;
        }
    }
    async subscribe() {
        this.updating = true;
        try {
            // We can only accept integer whole numbers
            // so lets round down to closes integer
            this.form.quantity = Math.floor(this.form.quantity);
            await ProjectService.createSubscription(this.form)
                .then(async (response) => {
                await this.$store.dispatch('user/getUserProfile');
                await this.$store.dispatch('notification/showSuccessModal', 'Thanks! We have added this project to your monthly subscription.');
                this.checkSubscription();
                this.has_subscription = true;
                this.updating = false;
            })
                .catch((e) => {
                this.$store.dispatch('notification/showErrorModal', e.response.data.message);
                this.updating = false;
            });
        }
        catch (error) {
            await this.$store.dispatch('notification/showErrorModal', 'We experienced an error creating a subscription, please try again in a few moments.');
        }
    }
    async cancel() {
        this.loading = true;
        try {
            await ProjectService.cancelSubscription({
                project_id: this.project.id,
            })
                .then((response) => {
                this.$store.dispatch('user/getUserProfile');
                this.$store.dispatch('notification/showSuccessModal', 'Success! We have removed this project from your monthly donations.');
                this.checkSubscription();
                this.has_subscription = false;
                this.loading = false;
            })
                .catch((e) => {
                this.$store.dispatch('notification/showErrorModal', e.response.data.message);
                this.loading = false;
            });
        }
        catch (error) {
            this.$store.dispatch('notification/showErrorModal', 'We experienced an error cancelling your subscription, please try again in a few moments.');
        }
    }
};
__decorate([
    Prop()
], ProjectSubscriptionForm.prototype, "project", void 0);
ProjectSubscriptionForm = __decorate([
    Component({
        components: { TextInput, SelectInput, Btn },
    })
], ProjectSubscriptionForm);
export default ProjectSubscriptionForm;
