import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
// componenets
import Gallery from '@/components/Gallery/Gallery.vue';
import LabelList from '@/components/Label/LabelList/LabelList.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let ProjectContent = class ProjectContent extends Vue {
};
__decorate([
    Prop()
], ProjectContent.prototype, "project", void 0);
ProjectContent = __decorate([
    Component({
        components: {
            Gallery,
            LabelList,
            MarkdownFormatter,
        },
    })
], ProjectContent);
export default ProjectContent;
