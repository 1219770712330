import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import Sidebar from '@/components/Elements/Sidebar.vue';
import Expander from '@/components/Expander/Expander.vue';
import SocialShare from '@/components/SocialShare/SocialShare.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import ExpanderMap from '@/components/Map/ExpanderMap/ExpanderMap.vue';
import OfferCard from '@/components/OfferCard/OfferCard.vue';
import SocialLinks from '@/components/SocialLinks/SocialLinks.vue';
import ProjectContent from '@/views/Projects/Sections/ProjectContent.vue';
import ProjectDonateForm from '@/views/Projects/Components/ProjectDonate/ProjectDonateForm.vue';
import ProjectSubscriptionForm from '@/views/Projects/Components/ProjectSubscription/ProjectSubscriptionForm.vue';
import MemberLookup from '@/views/Members/Components/MemberLookup.vue';
import OverlayBasic from '@/components/OverlayBasic/OverlayBasic.vue';
let ProjectPage = class ProjectPage extends Vue {
    constructor() {
        super(...arguments);
        this.coverImage = '';
        this.coverImageCopyright = '';
        this.showPaymentOptions = false;
        this.showPledge = true;
        this.isFavourited = false;
        // Send link to member
        this.showMemberSelect = false;
        this.shareInfo = {
            url: `${process.env.VUE_APP_URL}project/${this.$route.params.slug}`,
            title: `Africa Geographic - projects`,
            description: 'Get involved in projects through Africa Geographic',
            quote: 'Get involved in projects through Africa Geographic',
            hashtags: `projects,africa,africageographic,club`,
            twitterUser: 'africageo',
        };
    }
    async mounted() {
        await this.getProject();
        await this.getCoverImage();
        this.getCoverImageCopyright();
        await this.checkEngagements();
        await this.$store.dispatch('engagements/setEngagementType', {
            engagementType: '',
            engagementSlug: '',
        });
    }
    handleDonateClick() {
        window.open(this.project.donation_external_url, "_blank");
    }
    handleMoreInfoClick() {
        window.open(this.project.more_info_external_url, "_blank");
    }
    handleShareMemberClick() {
        if (!this.$store.getters['auth/loggedIn']) {
            this.$store.dispatch('notification/showErrorModalGuest', "Please register or login to use this feature.");
            return;
        }
        else {
            this.showMemberSelect = true;
        }
    }
    async getProject() {
        await this.$store.dispatch('projects/getProjectBySlug', this.$route.params.slug);
    }
    async favourite() {
        if (!this.$store.getters['auth/loggedIn']) {
            this.$store.dispatch('notification/showErrorModalGuest', "Please register or login to use this feature.");
            return;
        }
        await this.$store.dispatch('projects/favourite', this.project.slug);
        await this.checkEngagements();
    }
    // Engagements
    async checkEngagements() {
        if (this.project && this.project.favorite_projects && this.project.favorite_projects.length > 0) {
            this.isFavourited = !!this.project.favorite_projects.filter((user) => user.id === this.profile.id)[0];
        }
        else {
            this.isFavourited = false;
        }
    }
    getCoverImage() {
        if (this.project.hero_image && this.project.hero_image.public_url) {
            // if the project has a hero image set
            this.coverImage = this.project.hero_image.public_url;
        }
        else if (this.project.hero_image_id && this.project.images) {
            // if project has hero_image_id and images
            this.coverImage = this.project.images.filter((image) => image.id === this.project.hero_image_id)[0].public_url;
        }
        else if (this.project.images && this.project.images.length > 0) {
            this.coverImage = this.project.images[0].public_url;
        }
    }
    getCoverImageCopyright() {
        if (this.project && this.project.hero_image && this.project.hero_image.public_url && this.project.hero_image_copyright) {
            this.coverImageCopyright = this.project.hero_image_copyright;
        }
        else {
            return this.coverImageCopyright = '';
        }
    }
};
__decorate([
    Getter('activeProject', { namespace: 'projects' })
], ProjectPage.prototype, "project", void 0);
__decorate([
    Getter('getProfile', { namespace: 'user' })
], ProjectPage.prototype, "profile", void 0);
ProjectPage = __decorate([
    Component({
        components: {
            Btn,
            Sidebar,
            Expander,
            SocialShare,
            ProfileCard,
            CoverImage,
            ExpanderMap,
            OfferCard,
            SocialLinks,
            ProjectContent,
            ProjectDonateForm,
            ProjectSubscriptionForm,
            MemberLookup,
            OverlayBasic
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | project',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic project',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/project` }],
            };
        },
    })
], ProjectPage);
export default ProjectPage;
